
























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUott1721M6_Task26',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {image: '/img/assignments/uOttawaTask26_optionA.png', value: 'oxidationTwo'},
        {image: '/img/assignments/uOttawaTask26_optionB.png', value: 'oxidationOne'},
        {image: '/img/assignments/uOttawaTask26_optionC.png', value: 'c'},
        {image: '/img/assignments/uOttawaTask26_optionD.png', value: 'oxidationNitrogen'},
      ],
    };
  },
};
